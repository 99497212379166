import { UsersIcon } from '@heroicons/react/outline'

import { shareChallengeLink } from '../../lib/share'

export const ChallengeFriend = ({
  handleShareToClipboard,
}: {
  handleShareToClipboard: () => void
}) => {
  return (
    <div className="text-center -mt-[1%] mb-2">
      <button
        className="underline underline-offset-4 px-6 py-2 text-slate-600 dark:text-white"
        onClick={() => shareChallengeLink(handleShareToClipboard)}
      >
        <UsersIcon className="inline-block mr-2 h-5 w-5" />
        Challenge a friend
      </button>
    </div>
  )
}
