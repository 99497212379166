const CLUE_REVEAL_GUESSES = 3

type Props = {
  onClick: () => void
  guesses: string[]
  isGameOver: boolean
}

export function enoughGuesses(guesses: string[]): boolean {
  return guesses.length >= CLUE_REVEAL_GUESSES
}

export const GiveUp = ({ onClick, guesses, isGameOver }: Props) => {
  if (isGameOver) return null
  if (!enoughGuesses(guesses)) return null

  return (
    <button
      className="text-white bg-red-500 px-4 py-2 mx-4 rounded"
      onClick={onClick}
    >
      Give up?
    </button>
  )
}
