import { useEffect, useState } from 'react'
import {
  ChartBarIcon,
  CogIcon,
  InformationCircleIcon,
  TranslateIcon,
} from '@heroicons/react/outline'
import { GAME_TITLE } from '../../constants/strings'
import { isToday } from '../../lib/words'

import { Back } from '../navbar/Back'
import { Today } from '../navbar/Today'

type Props = {
  setIsInfoModalOpen: (value: boolean) => void
  setIsStatsModalOpen: (value: boolean) => void
  setIsSettingsModalOpen: (value: boolean) => void
}

declare global {
  interface Window {
    cookieconsent: {
      cookieConsentObject: {
        userConsent: {
          acceptedLevels: {
            functionality: boolean
            'strictly-necessary': boolean
            targeting: boolean
            tracking: boolean
          }
        }
      }
    }
  }
}

export const Navbar = ({
  setIsInfoModalOpen,
  setIsStatsModalOpen,
  setIsSettingsModalOpen,
}: Props) => {
  const [showGoogleTranslatePopup, setShowGoogleTranslatePopup] =
    useState(false)

  // Google Translate
  useEffect(() => {
    const googleTranslatePopup = document.getElementById(
      'google-translate-container'
    )
    if (googleTranslatePopup) {
      if (
        window.cookieconsent?.cookieConsentObject?.userConsent?.acceptedLevels
          ?.functionality
      ) {
        googleTranslatePopup.querySelector('small')?.remove()
      }
      googleTranslatePopup.style.display = showGoogleTranslatePopup
        ? 'block'
        : 'none'
      googleTranslatePopup.querySelector('select')?.focus()
    }
  }, [showGoogleTranslatePopup])

  return (
    <div className="navbar">
      <div className="navbar-content px-2">
        <div className="left-icons">
          <InformationCircleIcon
            className="h-6 w-6 mr-8 cursor-pointer dark:stroke-white"
            onClick={() => setIsInfoModalOpen(true)}
          />
        </div>

        <div className="flex items-center">
          <div className="text-xl ml-2.5 font-bold dark:text-white relative">
            <Back />
            {GAME_TITLE}
            <Today />
          </div>
        </div>

        <div className="right-icons">
          <TranslateIcon
            className="h-6 w-6 mr-3 cursor-pointer dark:stroke-white"
            onClick={() =>
              setShowGoogleTranslatePopup(!showGoogleTranslatePopup)
            }
          />
          {isToday ? (
            <ChartBarIcon
              className="h-6 w-6 mr-3 cursor-pointer dark:stroke-white"
              onClick={() => setIsStatsModalOpen(true)}
            />
          ) : null}
          <CogIcon
            className="h-6 w-6 cursor-pointer dark:stroke-white"
            onClick={() => setIsSettingsModalOpen(true)}
          />
        </div>
      </div>
      <hr></hr>
    </div>
  )
}
