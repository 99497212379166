import { solutionIndex } from '../../lib/words'

export const Back = () => {
  if (solutionIndex === 0) return null
  return (
    <a
      href={`?game=${solutionIndex}`}
      className="transition-colors absolute -left-12 sm:-left-24 -top-2 uppercase text-sm p-2"
    >
      <div className="relative back-icon">
        <img
          src="/icons/cal-back.png"
          alt="Try the previous days word"
          className="h-6 w-6"
        />
      </div>
    </a>
  )
}
