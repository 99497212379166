import { useState } from 'react'

const CLUE_REVEAL_GUESSES = 3
const CLUE_2_REVEAL_GUESSES = 5

type Props = {
  solution: string
  guesses: string[]
  isGameOver: boolean
}

export function firstLetterGuessed(
  solution: string,
  guesses: string[]
): boolean {
  // check for first letter of solution in first letter of each guesses
  for (let i = 0; i < guesses.length; i++) {
    if (solution[0] === guesses[i][0]) {
      return true
    }
  }
  return false
}
export function lastLetterGuessed(
  solution: string,
  guesses: string[]
): boolean {
  // check for last letter of solution in first last of each guesses
  for (let i = 0; i < guesses.length; i++) {
    if (solution[solution.length - 1] === guesses[i][guesses[i].length - 1]) {
      return true
    }
  }
  return false
}

export function enoughGuesses(guesses: string[], clueNumber: number): boolean {
  return (
    guesses.length >=
    (clueNumber === 1 ? CLUE_REVEAL_GUESSES : CLUE_2_REVEAL_GUESSES)
  )
}

export const Clue = ({ solution, guesses, isGameOver }: Props) => {
  const [revealFirstClue, setFirstRevealClue] = useState(false)
  const [revealLastClue, setLastRevealClue] = useState(false)

  if (isGameOver) return null

  const gotFirstLetter = firstLetterGuessed(solution, guesses)
  const gotLastLetter = lastLetterGuessed(solution, guesses)

  // got both clue letters already?
  if (gotFirstLetter && gotLastLetter) return null

  let button = null
  // show first clue?
  if (enoughGuesses(guesses, 1) && !gotFirstLetter && !revealFirstClue)
    button = (
      <button
        className="text-white bg-green-500 px-4 py-2 mx-4 rounded"
        onClick={() => setFirstRevealClue(true)}
      >
        Want a clue?
      </button>
    )

  // show Last clue?
  if (
    !button &&
    !revealLastClue &&
    ((enoughGuesses(guesses, 1) && gotFirstLetter && !revealFirstClue) ||
      (enoughGuesses(guesses, 2) && !gotLastLetter))
  )
    button = (
      <button
        className="text-white bg-green-500 px-4 py-2 mx-4 rounded"
        onClick={() => setLastRevealClue(true)}
      >
        Want {revealFirstClue ? 'another' : 'a'} clue?
      </button>
    )

  return (
    <>
      {revealFirstClue ? (
        <div className="text-center mb-4 dark:text-white">
          The word starts with a{' '}
          <strong className="p-1 rounded shadowed bg-green-500 text-white border-green-500">
            {solution[0]}
          </strong>
          !
        </div>
      ) : null}

      {revealLastClue ? (
        <div className="text-center mb-4 dark:text-white">
          The word ends with a{' '}
          <strong className="p-1 rounded shadowed bg-green-500 text-white border-green-500">
            {solution[solution.length - 1]}
          </strong>
          !
        </div>
      ) : null}

      {button}
    </>
  )
}
