import { ReactComponent as TwitterLogo } from './Twitter.svg'

import gamesList from '../../constants/games.json'

import './Sidebar.css'

type LinkProps = {
  icon?: string
  iconImage?: string
  name: string
  url: string
  info?: string
  className?: string
}
export const SidebarLink = ({
  icon,
  iconImage,
  name,
  url,
  info,
  className,
}: LinkProps) => {
  return (
    <a className={className ? className : 'sidebar-game'} href={url}>
      <span className="subtitle">
        {iconImage && (
          <img className="inline align-baseline" src={iconImage} alt={name} />
        )}
        {icon} {name}
      </span>
      {info && <span>{info}</span>}
    </a>
  )
}

export const Sidebar = () => {
  function toggleSidebar() {
    document.body.classList.toggle('nav-open')
  }

  function closeSidebar() {
    document.body.classList.remove('nav-open')
  }

  return (
    <nav className="sidebar-container">
      <button className="sidebar-toggle" onClick={toggleSidebar}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth="2"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M4 6h16M4 12h16M4 18h16"
          />
        </svg>
      </button>
      <div className="sidebar">
        <div className="sidebar-header">
          <h2>Play Daily Games</h2>
          <button className="sidebar-close" onClick={closeSidebar}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <div className="sidebar-games">
          <SidebarLink
            key="https://anywordle.games/"
            iconImage="https://anywordle.games/favicon-16x16.png"
            name="AnyWordle"
            url="https://anywordle.games/"
            info="Guess the different length word each day"
          />
        </div>
        <div className="sidebar-header">
          <h2>Play Who Are Ya Games</h2>
        </div>
        <div className="sidebar-games">
          {gamesList.map((game) => {
            return (
              <SidebarLink
                key={game.url}
                icon={game.icon}
                name={game.name}
                url={game.url}
                info={game.info}
              />
            )
          })}
        </div>
        <div className="sidebar-footer">
          <a
            href="https://twitter.com/_who_are_ya"
            rel="noreferrer"
            target="_blank"
          >
            <TwitterLogo />
          </a>
        </div>
      </div>
    </nav>
  )
}
