export const formatTimer = (time: number) => {
  const minutes = Math.floor((time / 60) % 60)
    .toFixed(0)
    .padStart(2, '0')
  const seconds = (time % 60).toFixed(0).padStart(2, '0')
  if (time / 60 >= 60) {
    const hours = Math.floor(time / 60 / 60)
      .toFixed(0)
      .padStart(2, '0')
    return `${hours}:${minutes}:${seconds}`
  }
  return `${minutes}:${seconds}`
}
