import { ClockIcon } from '@heroicons/react/outline'
import { formatTimer } from '../../lib/timer'

type Props = {
  time: number
}
export const Timer = ({ time }: Props) => {
  return (
    <div className="absolute top-12 right-0 mt-2 mr-2 dark:text-gray-100">
      <ClockIcon className="inline-block mr-2 h-5 w-5 -mt-1" />
      {formatTimer(time)}
    </div>
  )
}
