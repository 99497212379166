import { getGuessStatuses } from './statuses'
import { solutionIndex, unicodeSplit } from './words'
import { GAME_TITLE, GAME_URL } from '../constants/strings'
import { MAX_CHALLENGES } from '../constants/settings'
import { UAParser } from 'ua-parser-js'
import { formatTimer } from './timer'

const webShareApiDeviceTypes: string[] = ['mobile', 'smarttv', 'wearable']
const parser = new UAParser()
const browser = parser.getBrowser()
const device = parser.getDevice()

declare global {
  interface Window {
    dataLayer: Record<string, any>[]
  }
}

window.dataLayer = window.dataLayer || []

export const shareStatus = (
  solution: string,
  guesses: string[],
  time: number,
  lost: boolean,
  isHardMode: boolean,
  isDarkMode: boolean,
  isHighContrastMode: boolean,
  handleShareToClipboard: () => void
) => {
  let textToShare =
    `${GAME_TITLE} ${solutionIndex} ${
      lost ? 'X' : guesses.length
    }/${MAX_CHALLENGES}${isHardMode ? '*' : ''}\n\n` +
    generateEmojiGrid(
      solution,
      guesses,
      getEmojiTiles(isDarkMode, isHighContrastMode)
    )

  if (time) {
    textToShare += `\n\n🕛 Completed in ${formatTimer(time)}`
  }

  const shareData = {
    text: textToShare + '\n',
    url: GAME_URL,
  }

  let shareSuccess = false

  try {
    if (attemptShare(shareData)) {
      window.dataLayer.push({
        event: 'share',
        share_type: 'share_status',
        game_count: solutionIndex + 1,
      })
      navigator.share(shareData)
      shareSuccess = true
    }
  } catch (error) {
    shareSuccess = false
  }

  if (!shareSuccess) {
    window.dataLayer.push({
      event: 'share',
      share_type: 'share_status',
      game_count: solutionIndex + 1,
    })
    navigator.clipboard.writeText(`${shareData.text}\n${shareData.url}`)
    handleShareToClipboard()
  }
}

export const generateEmojiGrid = (
  solution: string,
  guesses: string[],
  tiles: string[]
) => {
  return guesses
    .map((guess) => {
      const status = getGuessStatuses(solution, guess)
      const splitGuess = unicodeSplit(guess)

      return splitGuess
        .map((_, i) => {
          switch (status[i]) {
            case 'correct':
              return tiles[0]
            case 'present':
              return tiles[1]
            default:
              return tiles[2]
          }
        })
        .join('')
    })
    .join('\n')
}

const attemptShare = (shareData: object) => {
  return (
    // Deliberately exclude Firefox Mobile, because its Web Share API isn't working correctly
    browser.name?.toUpperCase().indexOf('FIREFOX') === -1 &&
    webShareApiDeviceTypes.indexOf(device.type ?? '') !== -1 &&
    navigator.canShare &&
    navigator.canShare(shareData) &&
    navigator.share
  )
}

const getEmojiTiles = (isDarkMode: boolean, isHighContrastMode: boolean) => {
  let tiles: string[] = []
  tiles.push(isHighContrastMode ? '🟧' : '🟩')
  tiles.push(isHighContrastMode ? '🟦' : '🟨')
  tiles.push(isDarkMode ? '⬛' : '⬜')
  return tiles
}

export const shareChallengeLink = (handleShareToClipboard: () => void) => {
  const shareData = {
    text: `⚔️ I challenge you to beat me at ${GAME_TITLE}\n`,
    url: GAME_URL + '/?utm_source=share&utm_medium=challenge',
  }

  let shareSuccess = false

  try {
    if (attemptShare(shareData)) {
      window.dataLayer.push({
        event: 'share',
        share_type: 'challenge_friend',
        game_count: solutionIndex + 1,
      })
      navigator.share(shareData)
      shareSuccess = true
    }
  } catch (error) {
    shareSuccess = false
  }

  if (!shareSuccess) {
    window.dataLayer.push({
      event: 'share',
      share_type: 'challenge_friend',
      game_count: solutionIndex + 1,
    })
    navigator.clipboard.writeText(`${shareData.text}\n${shareData.url}`)
    handleShareToClipboard()
  }
}
