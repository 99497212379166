import { isToday } from '../../lib/words'

export const Today = () => {
  if (isToday) return null
  return (
    <a
      href="."
      className="absolute -right-12 sm:-right-24 -top-2 uppercase text-sm p-2"
    >
      <img
        src="/icons/cal-today.png"
        alt="Try the todays word"
        className="h-6 w-6"
      />
    </a>
  )
}
